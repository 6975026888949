import React from "react"
import { Box, Heading, Grid, Text } from "theme-ui"
import SEO from "../components/SEO"
import PageHeader from "../components/PageHeader"
import Layout from "../components/Layout"
import RegisterToday from "../components/RegisterToday"
import ContentSection from "./ContentSection"
import SpecialityGrid from "./SpecialityGrid"

// images
import ComingSoonImage from "../../images/comingSoon.svg"

interface ComingSoonPageProps {
  header: string
}

const ComingSoonPage = (props: ComingSoonPageProps) => {
  const { header } = props

  return (
    <Box>
      <SEO title={header} />
      <Layout>
        <PageHeader>
          <Text sx={{ fontSize: [1, 3], fontStyle: "italic" }}>
            SARKAR IAS ACADEMY is one of the most Trusted, Technically
            well-equipped institutions in Chennai for coaching UPSC,TNPSC, CIVIL
            ASPIRANTS WITH FREE INTERVIEW AND HIGHLY-QUALITY EDUCATIONAL
            RESOURCES CAN BE PRESENTED AS METICULOUSLY CRAFTED STUDY MATERIALS.
          </Text>
        </PageHeader>
        <Box pb={5}>
          <Heading
            mt={5}
            mb={4}
            sx={{ textAlign: "center", fontSize: [5, null, 6] }}
          >
            {header}
          </Heading>
          <Grid
            sx={{
              maxWidth: "620px",
              margin: "auto",
              justifyItems: "center",
              alignItems: "center",
            }}
            columns={[1, 1, 2]}
            gap={[2, 2, 4]}
          >
            <img width="280" src={ComingSoonImage} alt="Coming Soon" />
            <Text variant="secondary">
              We are currently updating our website.
              <br /> Details will be added soon. <br /> Stay Tuned.
            </Text>
          </Grid>
        </Box>
        {/* Why Sarkar IAS?*/}
        <ContentSection
          header="Why Sarkar IAS ACADEMY?"
          noHeaderBorder={true}
          body={<SpecialityGrid />}
        />

        <RegisterToday />
      </Layout>
    </Box>
  )
}

export default ComingSoonPage
