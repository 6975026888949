/** @jsx jsx */
import React from "react"
import { Flex, Image, Box, Heading, Text, Grid, jsx } from "theme-ui"
import StyledNavLink from "./StyledNavLink"

// images
import StudentIcon from "../../images/student.svg"
import TestSeriesIcon from "../../images/testSeries.svg"
import InterviewGuidanceIcon from "../../images/interviewGuidance.svg"
import OnlineClassIcon from "../../images/onlineCourse.svg"
import TrustedIcon from "../../images/trusted.svg"
import InfrastructureIcon from "../../images/school.svg"
import { Plus } from "@emotion-icons/boxicons-regular/Plus"

interface SpecialityProps {
  image: string
  heading: string
  bodyText: string
}

const Speciality = (props: SpecialityProps) => {
  const { image } = props

  return (
    <Flex sx={{ alignItems: "center", maxWidth: "500px" }}>
      <Image mr={3} src={image} alt={"icon"} sx={{ maxWidth: ["70px","100px"] }} />
      <Box ml={2} sx={{ color: "#390000" }}>
        <Heading sx={{ fontSize: [2, 3, 3] }} mb={2}>
          {props.heading}
        </Heading>
        <Text sx={{ fontSize: [1, 2, 2] }} variant="secondary">
          {props.bodyText}
        </Text>
      </Box>
    </Flex>
  )
}

const SpecialityGrid = (): JSX.Element => {
  return (
    <Box>
      <Grid
        gap={5}
        columns={[1, 2]}
        sx={{
          justifyItems: "center",
          maxWidth: "1200px",
          marginTop: ["-40px", "0px"],
        }}
      >
        <Box data-sal="slide-right">
          <Speciality
            image={StudentIcon}
            heading="Achiever Student Interaction"
            bodyText={`Engaging with accomplished students periodically, seeking insights from past exam 
toppers , can unveil effective strategies for success in the educational journe`}
          />
        </Box>
        <Box data-sal="slide-left">
          <Speciality
            image={TestSeriesIcon}
            heading="Test Series"
            bodyText="Consistent administration of  mock tests, mirroring the actual exam pattern, aids
            students in accessing their preparation status and refining their strategies"
          />
        </Box>
        <Box data-sal="slide-right">
          <Speciality
            image={InterviewGuidanceIcon}
            heading="Free Interview Guidance"
            bodyText="Conducting complimentary mock interviews aids students in UPSC preparation, 
     offering valuable practice for their actual interviews"
          />
        </Box>
        <Box data-sal="slide-left">
          <Speciality
            image={OnlineClassIcon}
            heading="Online Classes"
            bodyText="Engaging in daily live classes - A virtual alternative eliminating the need for physical 
attendance."
          />
        </Box>
        <Box data-sal="slide-right">
          <Speciality
            image={TrustedIcon}
            heading="Most Trusted Academy"
            bodyText="A widely relied-upon academy, endorsed by students nationwide. Our reviews stand 
as a testament to this trust."
          />
        </Box>
        <Box data-sal="slide-left">
          <Speciality
            image={InfrastructureIcon}
            heading="Best Infrastructure"
            bodyText="Meticulously designed and brightly illuminated facilities to ensure an exceptional 
            learning experience for our students."
          />
        </Box>
      </Grid>
      <Box mt={4} sx={{ textAlign: "center" }}>
        <StyledNavLink to="/why">
          <Box
            sx={{
              textTransform: "capitalize",
              fontSize: 3,
              display: "inline-flex",
            }}
          >
            <Plus size="1.7rem" />
            More
          </Box>
        </StyledNavLink>
      </Box>
    </Box>
  )
}

export default SpecialityGrid
