import React from "react"
import { Box, Button, Text } from "theme-ui"
import { Link } from "gatsby"

const RegisterToday = (): JSX.Element => {
  return (
    <Box bg="primary" color="white" px={1} py={4} sx={{ textAlign: "center" }}>
      <Text mb={4} sx={{ fontSize: 5 }}>
        Register Today at SARKAR IAS ACADEMY
      </Text>
      <Link to="/enroll">
        <Button px={6} bg="#fff" color="text" sx={{ fontWeight: "bold" }}>
          {" "}
          Enroll Now{" "}
        </Button>
      </Link>
    </Box>
  )
}

export default RegisterToday
